
/* Cookie Preferences */
.ccprefs {
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 99999;
	width: 55%;
	background-color: white;
	transform: translateY(-50%) translateX(-50%);
	margin: 0 auto;
	box-shadow: 0 7px 9px 7px rgb(0 0 0 / 20%);
}
@media (max-width: 1012px) { 
	.ccprefs {
		width: 90%;
		max-height: 90%;
		overflow: auto;
	}
}

.ccprefs-wrapper {
	padding: 30px;
}
@media (max-width: 767px) { 
	.ccprefs-wrapper {
		padding: 20px;
	}
}

.ccprefs-content {
	margin-bottom: 30px;
}

.ccprefs-close {
	position: absolute;
	top: 30px;
	right: 30px;
	border-bottom: solid 1px black;
}

.ccprefs-close:hover {
	border-bottom: solid 2px black;
}

.ccprefs-title {
	font-size: 1.3rem;
	line-height: 1.8rem;
	font-weight: bold;
	margin-bottom: 15px;
}

.ccprefs-option {
	margin-bottom: 30px;
	position: relative;
	display: flex;
}

.ccprefs-select {
	flex: 0 0 30px;
}

.ccprefs-definition {
	flex: 1;
}

.ccprefs-definition p {
	padding: 0;
	margin-bottom: 7px;
}

.ccprefs-definition p:last-child {
	padding: 0;
	margin-bottom: 7px;
}

.ccprefs-definition p.ccprefs-subtitle {
	font-weight: bold;
}

.ccprefs-definition label {
	cursor: pointer;
}

.ccprefs-actions ul {
	padding: 0;
	margin: 0;
	list-style-type: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
@media (max-width: 767px) { 
	.ccprefs-actions ul {
		flex-direction: column;
	}
}

.ccprefs-actions ul li {
	flex: 0 0 49%;
}
@media (max-width: 767px) { 
	.ccprefs-actions ul li {
		width: 100%;
		margin-bottom: 20px;
	}
	.ccprefs-actions ul li:last-child {
		margin-bottom: 0px;
	}
}

.ccprefs-button--accept a {
	display: block;
	text-align: center;
	background-color: #000; 
	border: solid 1px #000;
	color: white;
	padding: 10px 15px;
}

.ccprefs-button--accept a:hover {
	background-color: #333; 
	border: solid 1px #333;
}

.ccprefs-button--confirm a {
	display: block;
	text-align: center;
	color: black;
	border: solid 1px black;
	padding: 10px 15px;
}

.ccprefs-button--confirm a:hover {
	background-color: #333; 
	color: white;
}

/* The cookie icon trigger  */
.ccicon {
	position: fixed;
	z-index: 99997;
	bottom: 0;
	left: 0;
	width: 75px;
	height: 75px;
}

.ccicon a {
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.ccicon a:after {
	content: "";
	position: absolute;
	z-index: 1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.ccicon svg {
	width: 100%;
}

.ccicon svg #triangle {
	fill: black;
}



/* Cookie Banner */
.ccbanner {
	position: fixed;
	z-index: 99999;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: white;
	box-shadow: 0 7px 9px 7px rgb(0 0 0 / 20%);
}

.ccbanner-wrapper {
	padding: 30px 30px;
	display: flex;
	align-items: center;
}
@media (max-width: 767px) { 
	.ccbanner-wrapper {
		padding: 20px;
		flex-direction: column;
	}
}

.ccbanner-content {
	flex: 1;
}
@media (max-width: 767px) { 
	.ccbanner-content {
		margin-bottom: 20px;
	}
}

.ccbanner-content p {
	padding: 0;
	margin: 0;
	margin-bottom: 7px;
}

.ccbanner-content p:last-child {
	margin-bottom: 0;
}

.ccbanner-title {
	font-size: 1.3rem;
	line-height: 1.8rem;
	font-weight: bold;
}

.ccbanner-content p a {
	border-bottom: solid 1px black;
}

.ccbanner-content p a:hover {
	border-bottom: solid 2px black;
}

.ccbanner-actions {
	flex: 0 0 300px;
	display: flex;
	justify-content: flex-end;
}
@media (max-width: 767px) { 
	.ccbanner-actions {
		flex: 1;
	}
}

.ccbanner-actions ul {
	padding: 0;
	margin: 0;
	list-style-type: none;
	display: flex;
	align-items: center;
}

.ccbanner-actions ul li {
	margin: 0 10px;
}

.ccbanner-actions ul li:last-child {
	margin-right: 0;
}

.ccbanner-button--accept a {
	background-color: #000; 
	color: white;
	padding: 10px 15px;
}

.ccbanner-button--accept a:hover {
	background-color: #333; 
}

.ccbanner-button--preferences a {
	border-bottom: solid 1px black;
}

.ccbanner-button--preferences a:hover {
	border-bottom: solid 2px black;
}


/* Underlay */
.ccunderlay {
	position: fixed;
	z-index: 99998;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(255, 255, 255, 0.75);
}

.ccunderlay--frosted {
	backdrop-filter: blur(2px);
}


